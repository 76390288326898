import * as React from "react"
import { StaticImage } from "gatsby-plugin-image"

const Hero = ({ children }) => {
    return (
        <section className="flex relative overflow-hidden sm:justify-end">
            <div className="relative z-10 text-center py-20 px-6 bg-black bg-opacity-70 sm:px-8 md:px-10 sm:w-1/2 sm:text-left">
                { children }
            </div>
            <div className="absolute inset-y-0 inset-x-0 z-0">
                <StaticImage src="../images/interno-del-bar.jpg" alt="Interno del bar" placeholder="blurred" layout="fullWidth" className="h-full object-colver" />
            </div>
        </section>
    )
}

export default Hero