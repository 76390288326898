import * as React from "react"
import { Link } from "gatsby"
import Hero from "../components/hero"
import Template from "../components/template"

const IndexPage = () => {
  return (
    <Template>
      <main className="max-w-screen-lg mx-auto">
        <Hero>
          <h1 className="text-4xl mb-4 text-mint">Il bar a tuttotondo</h1>
          <div className="text-white mb-6">Dalla colazione all'aperitivo siamo pronti ad offrirti i migliori prodotti in qualunque occasione.</div>
          <Link to="/menu/" className="bg-mint text-white px-8 py-2 rounded hover:bg-opacity-90">Scopri il nostro menu</Link>
        </Hero>
      </main>
    </Template>
  )
}

export default IndexPage
